<template>
  <div>
    <!-- Filters -->
    <filter-team
      :clubs="clubs"
      :countries="countries"
      :states="states"
      :check-permission="checkPermission"
      @filter-query="handleFilters"
    />
    <!--/ Filters -->
    <b-card class="mb-0">
      <div>
        <b-row>
          <b-col v-if="checkPermission(['CREATE_TEAMS', 'ALLOW_ALL'])">
            <div class="d-flex justify-content-end">
              <create-team-modal
                :countries="countries"
                :states="states"
                :clubs="clubs"
                :club-info="clubInfo"
                :check-permission="checkPermission"
                @successfully="getTeamsFilterReset()"
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <b-overlay
        :show="isLoading"
        opacity="1"
        :class="isLoading ? 'p-2 mb-1 mt-1' : ''"
      >
        <template #overlay>
          <div class="d-flex align-items-center justify-content-center mt-1">
            <b-spinner
              type="border"
              variant="primary"
            />
          </div>
          <div class="d-flex justify-content-center">
            <p class="pt-1">
              {{ $t('loading') }}
            </p>
          </div>
        </template>
        <b-table
          ref="refUserListTable"
          class="position-relative mt-1"
          responsive
          :fields="headerTable"
          :items="teamList.data"
          primary-key="id"
          show-empty
          :empty-text="$t('generic.noRecordsFound')"
        >
          <!-- Column: club name -->
          <template #cell(club_name)="data">
            <span v-if="data.item.club != null">
              {{ data.item.club.club_name }}
            </span>
            <span v-else> N/A </span>
          </template>
          <!-- Column: status -->
          <template #cell(status)="data">
            <div v-if="checkPermission(['CHANGE_STATUS_TEAMS', 'ALLOW_ALL'])">
              <b-form-checkbox
                v-model="data.item.status"
                switch
                :value="true"
                :unchecked-value="false"
                @change="updateStatusTeam(data.item)"
              />
            </div>
            <div v-else>
              <span v-if="data.item.status === 1">
                {{ $t('generic.active') }}
              </span>
              <span v-else-if="data.item.status === 0">
                {{ $t('generic.inactive') }}
              </span>
              <span v-else>
                {{ $t('generic.removed') }}
              </span>
            </div>
          </template>
          <!-- Column: created_at -->
          <template #cell(created_at)="data">
            <span v-if="data.item.created_at != null">
              {{ data.item.created_at | formatDateTime }}
            </span>
            <span v-else> N/A </span>
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <edit-team-modal
              :clubs="clubs"
              :states="states"
              :countries="countries"
              :team="data.item"
              :club-info="clubInfo"
              :check-permission="checkPermission"
              @successfully="getTeamsFilterReset()"
            />
          </template>
        </b-table>
      </b-overlay>
      <app-paginator
        :data-list="teamList"
        @pagination-data="getTeams"
      />
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import checkPermission from '@/auth/permissions'
import FilterTeam from './FilterTeam.vue'
import CreateTeamModal from './CreateTeamModal.vue'
import EditTeamModal from './EditTeamModal.vue'
import AppPaginator from '../components/app-paginator/AppPaginator.vue'

export default {
  components: {
    FilterTeam,
    CreateTeamModal,
    EditTeamModal,
    AppPaginator,
  },
  data() {
    return {
      isLoading: false,
      filtersObj: {},
      tempQuery: {},
      paramsObj: {
        page: 1,
        perPage: 15,
        included: 'team,club',
      },
    }
  },
  computed: {
    ...mapGetters({
      role: 'role',
      teamList: 'teamStore/teamList',
      team: 'teamStore/team',
      countries: 'countries',
      states: 'states',
      clubs: 'clubList',
      clubInfo: 'clubInfo',
    }),
    headerTable() {
      return [
        {
          key: 'name',
          label: this.$t('generic.name'),
          sortable: true,
        },
        {
          key: 'club_name',
          label: this.$t('corporate.club'),
          sortable: true,
        },
        {
          key: 'number_participants',
          label: this.$t('generic.noParticipants'),
          sortable: true,
        },
        {
          key: 'status',
          label: this.$t('generic.status'),
          sortable: true,
        },
        {
          key: 'created_at',
          label: this.$t('generic.register'),
          sortable: true,
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
        },
      ]
    },
  },
  watch: {
    '$i18n.locale': function i18nlocale() {
      this.fetchCountries()
    },
  },
  mounted() {
    if (checkPermission(['VIEW_TEAMS'])) {
      if (!this.checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])) {
        this.filtersObj.club_hash = this.clubInfo.hash
      }
      this.getTeams()
      this.fetchCountries()
    } else {
      this.$router.push({ name: 'not-authorized' })
    }
  },
  methods: {
    checkPermission,
    ...mapActions({
      fetchCountries: 'fetchCountries',
      fetchDataTeams: 'teamStore/fetchData',
      updateTeam: 'teamStore/updateTeam',
      changeStatusTeam: 'teamStore/changeStatusTeam',
      fetchClubs: 'fetchClubs',
    }),
    getTeamsFilterReset() {
      this.paramsObj.page = 1
      this.paramsObj.perPage = 15
      if (!this.checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])) {
        this.filtersObj.club_hash = this.clubInfo.hash
      }
      this.getTeams()
    },
    handleFilters(filterSelect) {
      if (filterSelect) {
        this.filtersObj = filterSelect
        this.getTeams()
      }
    },
    async getTeams(pagination) {
      this.isLoading = true
      if (pagination) {
        this.paramsObj.page = pagination.page
        this.paramsObj.perPage = pagination.perPage
      }
      this.tempQuery.paramsObj = this.paramsObj
      this.tempQuery.filtersObj = this.filtersObj
      await this.fetchDataTeams(this.tempQuery).then(() => {
      }).catch(error => {
        this.responseCatch(error)
      }).finally(() => {
        this.isLoading = false
      })
      await this.fetchClubs(this.tempQuery).then(response => { })
    },
    async updateStatusTeam(team) {
      await this.changeStatusTeam(team.hash).then(() => {
        this.statusUpdate(this.$t('the-team'), team.status)
      }).catch(error => {
        this.responseCatch(error)
      })
    },
  },
}
</script>
