<template>
  <div>
    <span class="mr-20">{{ $t('generic.createNewTeam') }}</span>
    <b-button
      variant="primary"
      class="btn-icon rounded-circle"
      :style="colorPrimaryBtn"
      @click="showTeamModal = true"
    >
      <feather-icon
        icon="PlusIcon"
      />
    </b-button>

    <b-modal
      id="createTeamModal"
      v-model="showTeamModal"
      centered
      :title="$t('generic.createNewTeam')"
      no-close-on-backdrop
      @close="resetForm"
    >
      <validation-observer ref="createTeamRules">
        <b-form ref="formRegister">
          <!-- Club -->
          <b-form-group
            v-if="!checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])"
          >
            <b>{{ $t('corporate.club') }}: </b> <label>{{ clubInfo.club_name }} </label>
          </b-form-group>
          <b-form-group>
            <label for="name">{{ $t('generic.name') }} <span class="text-danger">*</span></label>
            <validation-provider
              #default="{ errors }"
              rules="required|max:254|alphaNumSpacesRegex"
              name="name"
            >
              <b-form-input
                id="name"
                v-model="team.name"
                type="text"
                :placeholder="$t('generic.enterNameTeam')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <label for="name">{{ $t('generic.numberParticipants') }}: </label>
          {{ totalMember }}
          <!-- slider -->
          <vue-slider
            v-model="value"
            :min="1"
            :max="500"
          />
          <b-row>
            <b-col>
              <b-form-group>
                <!-- <label>Numero de indetificacion de camiseta</label> -->
                <label for="name">{{ $t('generic.from') }}</label>
                <validation-provider
                  #default="{ errors }"
                  rules="between:1,500"
                  name="integer"
                >
                  <b-form-input
                    id="name"
                    v-model="team.number_participants_min"
                    type="text"
                    readonly
                    :placeholder="$t('generic.enterNumberMembers')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <label for="name">{{ $t('generic.to') }}</label>
                <validation-provider
                  #default="{ errors }"
                  rules="between:1,500"
                  name="integer"
                >
                  <b-form-input
                    id="name"
                    v-model="team.number_participants_max"
                    type="text"
                    readonly
                    :placeholder="$t('generic.enterNumberMembers')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Excluir -->
          <b-form-group>
            <label>{{ $t('generic.excludeNumbers') }}</label>
            <v-select
              v-model="team.excluded_numbers"
              label="name"
              :options="handleExclude"
              multiple
              :placeholder="$t('generic.selectNumbersExclude')"
            >
              <div slot="no-options">
                {{ $t('generic.noRecordsFound') }}
              </div>
            </v-select>
          </b-form-group>

          <!-- Country -->
          <b-form-group
            v-if="checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])"
          >
            <label for="club-country-id">{{ $t('generic.country') }}</label>
            <v-select
              id="club-country-id"
              v-model="idCountry"
              label="name"
              :options="countries"
              :reduce="(item) => item.id"
              :placeholder="$t('generic.selectCountry')"
            >
              <div slot="no-options">
                {{ $t('generic.noRecordsFound') }}
              </div>
            </v-select>
          </b-form-group>

          <!-- City -->
          <b-form-group
            v-if="checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])"
          >
            <label for="club-state-id">{{ $t('generic.state') }}</label>
            <v-select
              id="club-state-id"
              v-model="idState"
              label="name"
              :options="states"
              :disabled="idCountry == null"
              :reduce="(item) => item.id"
              :placeholder="$t('generic.selectState')"
            >
              <div slot="no-options">
                {{ $t('generic.noRecordsFound') }}
              </div>
            </v-select>
          </b-form-group>

          <!-- Club -->
          <b-form-group
            v-if="checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])"
          >
            <label>{{ $t("corporate.club") }}</label>
            <v-select
              v-model="team.club_hash"
              :placeholder="$t('corporate.selectSportClub')"
              label="club_name"
              :options="clubs"
              :reduce="(item) => item.hash"
              :disabled="idCountry == null"
            >
              <div slot="no-options">
                {{ $t('generic.noRecordsFound') }}
              </div>
            </v-select>
          </b-form-group>
        </b-form>
      </validation-observer>

      <template #modal-footer>
        <div class="float-right">
          <b-button
            variant="secondary"
            pill
            @click="resetForm"
          >
            {{ $t('buttons.cancel') }}
          </b-button>

          <b-overlay
            :show="isLoadingCreate"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
            class="d-inline-block"
          >

            <b-button
              variant="primary"
              style="margin-left: 17px;"
              pill
              :style="colorPrimaryBtn"
              @click="saveTeamData"
            >
              {{ $t('buttons.save') }}
            </b-button>
          </b-overlay>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapGetters, mapActions } from 'vuex'
import VueSlider from 'vue-slider-component'
import { required } from '@validations'
import 'vue-slider-component/theme/antd.css'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    VueSlider,
  },
  props: {
    clubs: {
      type: Array,
      default: null,
    },
    clubInfo: {
      type: Object,
      required: true,
      default: null,
    },
    checkPermission: {
      type: Function,
      default: null,
    },
    countries: {
      type: Array,
      default: null,
    },
    states: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      showTeamModal: false,
      isLoadingCreate: false,
      idCountry: null,
      idState: null,
      required,
      min: 1,
      max: 51,
      excluded_numbers: null,
      tempQuery: {},
      paramsObj: {
        page: 1,
        perPage: 15,
      },
      filtersObj: {
        country_hash: null,
        state_hash: null,
        club_hash: null,
      },
    }
  },
  computed: {
    ...mapGetters({
      team: 'teamStore/team',
      colorPrimaryBtn: 'colorPrimaryBtn',
    }),
    // Number participans
    value: {
      get() {
        return [this.team.number_participants_min, this.team.number_participants_max]
      },
      set([min = 1, max = 50]) {
        this.team.number_participants_min = min
        this.team.number_participants_max = max
      },
    },
    totalMember() {
      const total = this.team.number_participants_max - this.team.number_participants_min
      if (total !== 0) {
        return total
      }
      return 50
    },
    handleExclude() {
      let index = this.team.number_participants_min
      const max = this.team.number_participants_max
      const element = []
      for (index; index < max; index += 1) {
        element.push(index)
      }
      return element
    },
  },
  watch: {
    idCountry(pCountry) {
      if (pCountry !== null) {
        this.filtersObj.country_hash = pCountry
        this.handleFiltersClub(this.filtersObj)
      }
      this.idState = null
      this.team.club_hash = null
    },
    idState(pState) {
      if (pState !== null) {
        this.filtersObj.state_hash = pState
        this.handleFiltersClub(this.filtersObj)
      }
      this.team.club_hash = null
    },
  },
  mounted() {
    // this.resetForm()
  },
  methods: {
    ...mapActions({
      fetchStateByCountryId: 'fetchStateByCountryId',
      fetchDataTeams: 'teamStore/fetchData',
      createNewTeam: 'teamStore/createNewTeam',
      fetchClubs: 'fetchClubs',
    }),
    async saveTeamData() {
      this.isLoadingCreate = true
      if (this.team.club_hash == null) {
        this.team.club_hash = this.clubInfo.hash
      }
      // this.team.club_hash = 1
      await this.$refs.createTeamRules.validate().then(async isValid => {
        if (!isValid) {
          return
        }
        this.team.number_participants = this.totalMember
        await this.createNewTeam(this.team).then(response => {
          this.responseSuccessCreate(response.data.message)
          this.$emit('successfully')
          this.resetForm()
        }).catch(error => {
          this.responseCatch(error)
        }).finally(() => {
          this.isLoadingCreate = false
        })
      })
    },
    async handleFiltersClub() {
      this.tempQuery.filtersObj = this.filtersObj
      await this.fetchStateByCountryId(this.filtersObj.country_hash)
      await this.fetchClubs(this.tempQuery).then(() => {
      }).catch(error => {
        this.responseCatch(error)
      })
    },
    resetForm() {
      this.showTeamModal = false
      this.team.name = null
      this.team.number_participants = 50
      this.team.number_participants_min = 1
      this.team.number_participants_max = 51
      this.team.excluded_numbers = null
      this.team.club_hash = null
      this.idCountry = null
      this.idState = null
    },
  },
}
</script>
<style scoped>

  /* @import 'vue-slider-component/theme/default.css'; */

  .mr-20 {
    margin-right: 20px !important;
  }
</style>
